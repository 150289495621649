<template>
  <div>
    <NavBar
      :title="pageTitle"
      @showNavigation="nav=!nav"
    />
    <v-main>
      <v-container fluid>
        <div class="d-flex align-center mb-4">
          <div
            class="flex-shrink-1 pl-2"
          >
            <v-img
              :src="`/img/class-societies/${user.cs.logo}`"
              height="60"
              width="80"
              contain
              eager
            />
          </div>
          <div class="text-h4 flex-grow-1 pl-3">
            {{ user.cs.name }}
          </div>
        </div>

        <Tabs class="">
          <Tab label="Open Findings">
            <OpenFindings>
              <template
                v-slot:proposed="{ finding }"
              >
                <Proposed :finding="finding" />
              </template>
              <template
                v-slot:cas="{ finding }"
              >
                <CAs :finding="finding" />
              </template>
            </OpenFindings>
          </Tab>
          <Tab label="Findings Archive">
            <Archive />
          </Tab>
          <Tab label="Advanced Search">
            <AdvancedSearch />
          </Tab>
        </Tabs>

      </v-container>
    </v-main>
  </div>
</template>

<script>
// import { computed } from '@vue/composition-api'
import NavBar from '@/components/UI/nav-bar'

import AdvancedSearch from '@/components/shared/advanced-search'
import Archive from '@/components/shared/archive'
import OpenFindings from '@/components/shared/open-findings'

import Proposed from './open-findings/proposed'
import CAs from './open-findings/cas'

export default {
  name: 'CSHome',
  components: {
    AdvancedSearch,
    Archive,
    CAs,
    NavBar,
    OpenFindings,
    Proposed,
  },
  mounted () {
    this.$emit('loaded')
  },
  setup (props, context) {
    const { $store } = context.root

    const user = $store.state.auth.user

    const pageTitle = 'Class Society Home'

    return {
      pageTitle,
      user
    }
  }
}
</script>
