<template>
  <v-sheet
    class="px-4 py-4 mr-5 mt-4"
    color="amber lighten-4"
    style="margin-left:140px"
    width="100%"
  >
    <div class="text-h5 pb-2 px-1 brown--text text--darken-2">{{ dictionary['acbProposedCA'].longname }}</div>

    <v-layout column>
      <TextArea
        auto-grow
        class="mb-2"
        field="acbProposedCA"
        height="auto"
        :value="text"
        :placeholder="`Enter ${dictionary['acbProposedCA'].longname} here ...`"
        @change="change"
      />
      <v-layout align-center>
        <v-layout align-center space-between class="brown--text text--darken-4">
          <div class="mr-2 px-1">
            {{ dictionary['acbProposedCompDate'].longname }} :
          </div>
          <div>
            <DatePicker
              field="acbProposedCompDate"
              :value="date"
              :past="false"
              :today="true"
              @change="change"
            />
          </div>
        </v-layout>
        <v-btn
          depressed
          color="primary"
          :loading="saving"
          :disabled="!(date && text)"
          @click="update"
        >
          Update
        </v-btn>
      </v-layout>
    </v-layout>
  </v-sheet>
</template>

<script>
import { dictionary } from '@/models/finding'

import DatePicker from '@/components/UI/date-picker'
import TextArea from '@/components/UI/forms/textarea'

export default {
  components: {
    DatePicker,
    TextArea
  },
  props: {
    finding: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      dictionary,
      date: null,
      text: null,
      saving: false
    }
  },
  methods: {
    change({ field, value}) {
      if (field === 'acbProposedCA'){
        this.text = value
      }
      if (field === 'acbProposedCompDate'){
        this.date = value
      }
      // console.log(field, value)
    },
    update() {
      this.saving = true
      const upd = {
        acbProposedCA: this.text,
        acbProposedCompDate: this.date
      }
      this.finding.patch({ data: upd }).then(() => {
        setTimeout(() => {
          this.saving = false
          this.$snotify.success('Details saved', 'Success!')
        }, 1000)
      })
    }
  },
  watch: {
    finding: {
      handler (newVal){
        this.text = newVal.acbProposedCA
        this.date = newVal.acbProposedCompDate
         ? new Date(newVal.acbProposedCompDate).toISOString().substr(0, 10)
         : null
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
